import { useState, useEffect, useContext } from 'react';
import { OAuthError, getData, postData, putData } from '../backend';
import { Switch, Route, Link, useHistory, useLocation } from 'react-router-dom';
import { CogIcon, LightningBoltIcon, TemplateIcon } from '@heroicons/react/outline';
import useUser from '../hooks/useUser';
import AppOnboarding from './AppPortal';
import AppAutoLogin from './AppAutoLogin';
import UserPanel from './User';
import SideBarItem from './SideBarItem';
import WorkflowList from './WorkflowList';
import TemplateList from './TemplateList';
import TemplateDetail from './TemplateDetail';
import Settings from './Settings';
import Page404 from './Page404';
import NewUserModal from './NewUserModal';
import WorkflowDetail from './WorkflowDetail';
import AppBanner from './AppBanner';
import PayWallModalTrial from './PayWallModalTrial';
import HubspotLanding from './HubspotLanding';
import LoadingModal from './LoadingModal';
import { SectionsContext, useModal } from '../contexts';
import { Source, Document, Workflow } from '../interfaces';
import OAuthModalHubspot from './OAuthModalHubspot';
import OAuthModalXero from './OAuthModalXero';
import OAuthModal from './OAuthModal';
import ErrorModal from './ErrorModal';
import useSWR from 'swr';
import packageJson from '../../package.json';
import useWorkflows from '../hooks/useWorkflows';
import AIDocumentBuilder from './AIDocumentBuilder';

function AppWorkflow() {
  const { user } = useUser();
  const pathname = useLocation().pathname;
  const [hasUpdatedUser, setHasUpdatedUser] = useState(false);

  const { workflow, template, settings } = useContext(SectionsContext);

  const { workflowUrl: lastWorkflowUrl } = workflow;
  const { templateUrl: lastTemplateUrl } = template;
  const { settingsUrl: lastSettingsUrl } = settings;

  const workflowUrl = pathname.includes('/w/') ? '' : lastWorkflowUrl;
  const templateUrl = pathname.includes('/templates') ? '/templates' : lastTemplateUrl;
  const settingsUrl = pathname.includes('/settings') ? '/settings' : lastSettingsUrl;

  const { data: apiVersionData } = useSWR("/version/", getData);

  const history = useHistory();
  const { results: workflows } = useWorkflows().data;

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const [showBanner, setShowBanner] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const referralToken = params["referral"];
  if (referralToken)
    localStorage.setItem("referralToken", referralToken);

  useEffect(() => {
    // TODO: Figure out where this stuff lives

    if (!user || hasUpdatedUser)
      return;

    setHasUpdatedUser(true);

    if (window.location.pathname.startsWith("/signup"))
      window.location.pathname = "/";

    const referralToken = localStorage.getItem("referralToken");
    if (referralToken && !user.referredBy)
      postData(`/auth/referral/`, { referralToken })
        .catch(console.error);

    setShowBanner(user.subscription?.tier === "trial");
    setShowUpgradeModal(user.subscription?.tier === "free" && !localStorage.getItem("dontShowUpgradeModal"));

    // @ts-ignore
    const profitwell = window.profitwell;

    profitwell('start', { 'user_email': user.email });
  }, [user, hasUpdatedUser]);

  const bannerHeight = 56;
  const contentOffset = (showBanner ? bannerHeight : 0);

  const { openModal, closeModal } = useModal();

  async function createNewWorkflow(stage: "SOURCE" | "DOCUMENT", blockType: string, file?: any, isPdf?: boolean) {
    //@ts-ignore
    const dataLayer = window.dataLayer;

    const subtitle = !!file
      ? "This may take a minute as we retreive the file from your Drive."
      : "This may take a minute as we create a new file in your Drive.";

    openModal(<LoadingModal title="Creating Workflow" subtitle={subtitle} />)

    const initialWorkflow = { "color": file?.color, "icon": file?.icon, name: file?.name ?? 'New Workflow' };
    const workflow: Workflow = await postData("/workflows/", initialWorkflow);

    try {
      if (stage === "SOURCE") {
        const sourceType = blockType;
        const source: Source = await postData(`/workflows/${workflow.id}/source/`, { sourceType });

        if (sourceType === "MICROSOFT_EXCEL" && file?.id)
          await putData(`/microsoft/config/${source.microsoftExcelConfig!.id}/`, { fileId: file.id });

        else if (file?.id)
          source.file = await putData(`/workflows/sources/${source.id}/file/`, { file });

        else if (["GOOGLE_SHEETS", "GOOGLE_FORMS"].includes(sourceType)) // Create file only for Google source types
          source.file = await postData(`/workflows/sources/${source.id}/file/`);

        if (sourceType === "GOOGLE_FORMS") // Enable auto-create
          putData(`/workflows/${workflow.id}/auto-create/`, { autoCreate: true });

        dataLayer.push({ 'source_type': sourceType, "document_type": undefined });
      }

      else if (stage === "DOCUMENT") {
        const documentType = blockType;
        const document: Document = await postData(`/workflows/${workflow.id}/documents/`, { documentType });

        if (file) {
          if (isPdf) {
            const content = new Blob([file], { type: "application/pdf" });

            const { url } = await postData(`/workflows/documents/${document!.id}/generate-pdf-upload-url/`);

            await fetch(url, {
              method: "PUT",
              headers: {
                "content-type": "application/pdf"
              },
              body: content
            });

            await postData(`/workflows/documents/${document!.id}/confirm-pdf-upload-status/`, { filename: file.name });
          } else
            document.file = await putData(`/workflows/documents/${document.id}/file/`, { file });
        }

        else if (documentType.startsWith("GOOGLE")) // Create file only for Google document types
          document.file = await postData(`/workflows/documents/${document.id}/file/`);

        dataLayer.push({ "source_type": undefined, 'document_type': documentType });
      }

      closeModal();

      if ((workflows?.length ?? 0) <= 1)
        history.push("/workflow/");
      else
        history.push("/w/" + workflow.id);

      dataLayer.push({ 'event': 'create_workflow', 'workflow_id': workflow.id, 'workflow_name': workflow.name });

    } catch (error) {
      if (error instanceof OAuthError)
        if (error.oauthUrl.includes("app.hubspot"))
          openModal(<OAuthModalHubspot oauthUrl={error.oauthUrl} onClose={() => history.push(`/w/${workflow.id}`)} />);
        else if (error.oauthUrl.includes("xero"))
          openModal(<OAuthModalXero oauthUrl={error.oauthUrl} onClose={() => history.push(`/w/${workflow.id}`)} />);
        else
          openModal(<OAuthModal oauthUrl={error.oauthUrl} />);
      else
        openModal(<ErrorModal details={error} />);
    }

    return workflow;
  }

  return (
    <Switch>
      <Route path="/auto-login" component={AppAutoLogin} />
      <Route path="/signup" component={AppOnboarding} />
      <Route path="/ai-document-builder" component={AIDocumentBuilder} />
      <Route path="*">
        <div className='flex flex-col h-screen font-sans bg-white'>
          {(user?.subscription?.tier === "free" || user?.subscription?.tier === "trial") &&
            <PayWallModalTrial isShown={showUpgradeModal} setIsShown={setShowUpgradeModal} />
          }
          <AppBanner isVisible={showBanner} setIsVisible={setShowBanner} style={{ height: bannerHeight }} openUpgradeModal={() => setShowUpgradeModal(true)} />
          <div className='flex'>
            <nav className='flex flex-col bg-white w-16 overflow-x-visible drop-shadow z-20'>
              <Link
                to="/"
                className='w-full h-14 flex items-center justify-center cursor-pointer hover:bg-gray-100'
                tabIndex={0}
              >
                <img src="https://static.portant.co/logo-mark-blue.svg" className='w-8 h-8' alt="Portant Logo" />
              </Link>
              <SideBarItem
                name='Workflows'
                icon={<LightningBoltIcon className='w-6 h-6' />}
                href={workflowUrl}
              />
              <SideBarItem
                name='Templates'
                icon={<TemplateIcon className='w-6 h-6' />}
                href={templateUrl}
              />
              <div className='mt-auto' />
              {user &&
                <>
                  <SideBarItem
                    name='Settings'
                    icon={<CogIcon className='w-6 h-6' />}
                    href={settingsUrl}
                  />
                </>
              }
              <div className="flex flex-col items-center justify-center font-gilroy font-semibold text-xs text-gray-400 w-16 h-16">
                <div>v{packageJson?.version}</div>
                <div>(v{apiVersionData?.version ?? "---"})</div>
              </div>
            </nav>
            <div className='flex flex-col h-full w-full'>
              <Switch>
                <Route path="/workflow"> {/* defaults to latest workflow via "__latest" id */}
                  <WorkflowDetail contentOffset={contentOffset} createNewWorkflow={createNewWorkflow} />
                </Route>
                <Route path="/w/:id">
                  <WorkflowDetail contentOffset={contentOffset} createNewWorkflow={createNewWorkflow} />
                </Route>
                <Route path="*">
                  <div className='flex flex-row items-center justify-between p-2 bg-white text-blue shadow z-10 h-14'>
                    <Link to='/'>
                      <h1 className='font-causten font-semibold text-blue text-xl ml-2 hover:underline' style={{ textDecorationColor: user?.activeTeam?.brandingColour }}>Portant</h1>
                    </Link>
                    <UserPanel />
                  </div>
                  <div className='flex flex-row flex-1'>
                    <div className='flex flex-col w-full overflow-hidden bg-gray-100' style={{ height: `calc(100vh - 56px - ${contentOffset}px)` }}>
                      <Switch>
                        <Route path="/" exact>
                          <WorkflowList contentOffset={contentOffset} createNewWorkflow={createNewWorkflow} />
                        </Route>
                        <Route path="/hubspot-from-scratch" component={HubspotLanding} exact />
                        <Route path='/templates/:id' component={TemplateDetail} />
                        <Route path='/templates'>
                          <TemplateList contentOffset={contentOffset} />
                        </Route>
                        {user &&
                          <Route path='/settings'>
                            <Settings contentOffset={contentOffset} />
                          </Route>
                        }
                        <Route path='*' component={Page404} />
                      </Switch>
                      <NewUserModal />
                    </div>
                  </div>
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </Route>
    </Switch>
  );
}

export default AppWorkflow;
