import { useState, useEffect } from 'react';
import { ConfettiProvider, ModalProvider, SectionsProvider } from "../contexts";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import useUser from '../hooks/useUser';
import AppPortal from './AppPortal';
import AppAutoLogin from './AppAutoLogin';
import Intercom from './Intercom';
import SessionManager from './SessionManager';
import LaunchManager from './LaunchManager';
import MobileLanding from './MobileLanding';
import AppWorkflow from './AppWorkflow';
import useScript from '../hooks/useScript';
import AppLoading from './AppLoading';
import AppCopper from './AppCopper';
import AppHubspot from './AppHubspot';
import { postData } from '../backend';
import { setAuthToken, setRefreshToken } from '../auth';
import AppPortalConversion from './AppPortalConversion';
import AIDocumentBuilder from './AIDocumentBuilder';

function App() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const referralToken = params["referral"];

  if (referralToken)
    localStorage.setItem("referralToken", referralToken);

  const { user, refreshUser, error, isLoading, isValidating } = useUser();

  const [width, setWidth] = useState<number>(window.innerWidth);

  const googleStatus = useScript("https://accounts.google.com/gsi/client", { 'data-use_fedcm_for_prompt': 'true' });
  const [googleIsLoaded, setGoogleIsLoaded] = useState(false);

  useEffect(() => {
    if (googleStatus !== "ready" || googleIsLoaded)
      return;

    async function loginUserViaGoogle(res: any) {
      postData("/auth/google-login/", { idToken: res["credential"] })
        .then(({ token, refreshToken, isNew }) => {
          setAuthToken(token);
          setRefreshToken(refreshToken);


          if (isNew) {
            const url = new URL(window.location.href);
            url.searchParams.set("new", "true");

            window.location.href = url.toString();
          }

          refreshUser();
        })
        .catch(console.error)
    }

    // @ts-ignore
    const google = window.google;

    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      prompt_parent_id: "google-signin-prompt",
      auto_select: true,
      cancel_on_tap_outside: false,
      callback: loginUserViaGoogle,
      use_fedcm_for_prompt: true,
    });

    setGoogleIsLoaded(true);
  }, [googleStatus, googleIsLoaded, refreshUser]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  useEffect(() => {
    if (!user)
      return;

    //@ts-ignore
    const dataLayer = window.dataLayer;
    dataLayer.push({ 'user_id': user.id });
  }, [user, isValidating]);

  if (window.location.pathname.startsWith("/copper/"))
    return <AppCopper isGoogleLoaded={googleIsLoaded} />;

  if (window.location.pathname.startsWith("/hubspot-picker/"))
    return <AppHubspot />;

  const isMobile: boolean = (width < 400);
  if (isMobile)
    return <MobileLanding user={user} isGoogleLoaded={googleIsLoaded} />;

  if (!googleIsLoaded || (!user && !error && isLoading))
    return <AppLoading />;

  return (
    <Router>
      <ModalProvider>
        <ConfettiProvider>
          <Intercom />
          <SessionManager />
          <LaunchManager />
          <Switch>
            <Route path="/auto-login" component={AppAutoLogin} />
            <Route path="/hubspot-get-started" component={AppPortalConversion} />
            <Route path="/google-forms-to-pdf" component={AppPortalConversion} />
            <Route path="/pdf-to-google-docs" component={AppPortalConversion} />
            <Route path="/ai-document-builder" component={AIDocumentBuilder} />
            <Route path="/invoices" component={AppPortalConversion} />
            <Route path="*">
              {!user
                ? <AppPortal />
                : (
                  <SectionsProvider>
                    <AppWorkflow />
                  </SectionsProvider>
                )}
            </Route>
          </Switch>
        </ConfettiProvider>
      </ModalProvider>
    </Router>
  );
}

export default App;
